import React from 'react';
import ScrollToTop from './assets/js/scrolToTop';
import Context from './assets/js/Context';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
// import NotFound from './assets/js/NotFound';
import SideBar from './system/common/sidebar';
import Cookies from "universal-cookie";
import host from './assets/js/Host';
import axios from 'axios';
import LoginDash from './system/common/login';
import getorderbyid from './system/dash/getOrderById';
import Main_Admin from './system/dash/Main_Admin';
import Wasil from './system/dash/wasil';
import Admins from './system/dash/admins';
import LoginV2 from './system/common/login_v2';
import Blocked from './pages/blocked';
import Expired from './system/dash/expired';
import Page from './system/dash/pageById';
import GeneralSetting from './system/dash/GeneralSetting';

import UserById from './system/dash/userById';
import EditDash from './system/dash/edit';
import Dayle_orders from './system/dash/dayle_orders';
import CustomerOrder from './system/dash/customerOrder';
import Items2Dash from './system/dash/items2';
import Employee from './system/dash/employee';
import Pages from './system/dash/pages';
import SubmitForm from "./pages/SubmitForm";
// import Chars from "./system/dash/charts";


import Login from "./pages/login";
import Home from "./pages/home";
import Orders from "./pages/orders";
import Items from "./pages/items";
import Done from './pages/done';
import Search from './pages/search';
import Order from './pages/orderById';
import Edit from './pages/edit';
import Print from './pages/print';
import Error from './pages/error';
import 'react-toastify/dist/ReactToastify.css';
import './assets/css/login.css';
import './assets/css/App.css';
import './assets/css/sidebar.css';
import './App.css';
const cookies = new Cookies();

class App extends React.Component {
  constructor() {
    super();
    this.state = {
      shopNum: '',
    }
  }
  componentDidMount() {
    if (!cookies.get('tokenAdmin')) {
      var header = { "Content-Type": "application/x-www-form-urlencoded", Authorization: cookies.get("tokenUser") };
      axios.get(host + `users/cart/`, { headers: header })
        .then(res => {

          this.setState({ shopNum: res.data.data.cart_items.item.length });
        })
        .catch(error => { console.log(error) })
    }
  }
  render() {
    return (
      <div >
        <Context.Provider value={{
          value: this.state, action: {
            RefreshComponent: () => {
              this.componentDidMount()
            },
          }
        }}>
          <div className="App">
            <BrowserRouter>
              <ScrollToTop>
                <Switch>
                  <Route exact path="/" component={Home} />
                  <Route path="/FromUser" component={SubmitForm} />
                  <Route path="/login" component={LoginV2} />
                  <Route path="/Orders" component={Orders} />
                  <Route path="/Items" component={Items} />
                  <Route path="/Done" component={Done} />
                  <Route path="/edit" component={Edit} />
                  <Route path="/search" component={Search} />
                  <Route path="/error" component={Error} />
                  <Route path="/tesst" component={Error} />
                  <Route path="/order" component={Order} />
                  <Route path="/Print2/:id" component={Print} />
                  {/* <Route path="/Chars/" component={Chars} /> */}
                  <Route path='/loginV2' component={LoginV2} />

                  <Route path='/loginDash' component={LoginDash} />
                  <Route path='/Expenses' component={SideBar} />
                  <Route path='/fromorders' component={SideBar} />
                  <Route path='/SearchDash' component={SideBar} />
                  <Route path='/fromordersReports' component={SideBar} />
                  <Route path='/fromordersWatting' component={SideBar} />
                  <Route path='/getorderbyid' component={getorderbyid} />
                  <Route path='/Report' component={SideBar} />
                  <Route path='/delevryReports' component={SideBar} />

                  <Route path='/SettingDash' component={SideBar} />
                  <Route path='/Cate2' component={SideBar} />
                  <Route path='/DoneOrder' component={SideBar} />
                  <Route path='/RejectOrder' component={SideBar} />
                  <Route path='/selles' component={SideBar} />
                  <Route path='/doneselles' component={SideBar} />
                  <Route path='/wattingselles' component={SideBar} />
                  <Route path='/apadningOrders' component={SideBar} />
                  <Route path='/cancelledOrders' component={SideBar} />
                  <Route path='/calledOrders' component={SideBar} />



                  <Route path='/printlist' component={SideBar} />
                  <Route path='/dayle_orders' component={Dayle_orders} />

                  <Route path='/sheet' component={SideBar} />

                  <Route path='/miss' component={SideBar} />
                  <Route path='/check' component={SideBar} />
                  <Route path='/PrintSelect' component={SideBar} />


                  <Route path='/wasil' component={Wasil} />

                  <Route path='/user' component={UserById} />


                  <Route path='/EditDash/:id' component={EditDash} />
                  <Route path='/CustomerOrderDash/:id' component={CustomerOrder} />
                  <Route path='/Items2Dash/:id' component={Items2Dash} />

                  <Route path='/se' component={Items2Dash} />
                  <Route path='/Employee' component={Employee} />
                  <Route path='/pages' component={Pages} />
                  <Route path='/page' component={Page} />
                  <Route path='/admins' component={Admins} />
                  <Route path='/Main_Admin' component={Main_Admin} />
                  <Route path='/blocked' component={Blocked} />
                  <Route path='/Expired' component={Expired} />
                  
                  
                  {/* <Route path='/page' component={Page} /> */}

                  <Route path='/GeneralSetting' component={GeneralSetting} />


                  {/* <Route  exact={true} path='*' component={NotFound} /> */}
                </Switch>
              </ScrollToTop>
            </BrowserRouter>
          </div>
        </Context.Provider>

      </div>
    );
  }
}

export default App;
