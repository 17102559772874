import React from 'react';
import Context from '../../assets/js/Context';
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import axios from 'axios';

import "react-datepicker/dist/react-datepicker.css";
import Component from "@reactions/component";
import { Pane, Dialog, Button, Label, Textarea } from 'evergreen-ui';
import { ToastContainer } from 'react-toastify';
import LocalPrintshopIcon from '@material-ui/icons//LocalPrintshop';

// import { Form } from 'react-bootstrap';

// import Lottie from 'lottie-react-web';
// import animation from '../../assets/json/clean.json';
import 'react-toastify/dist/ReactToastify.css';
import { createMuiTheme } from '@material-ui/core/styles';

import * as moment from "moment-timezone";
import Login from '../common/login';
import exportFromJSON from 'export-from-json'
import DataTable from 'react-data-table-component';


// import { JsonToExcel } from "react-json-to-excel";




// const mystyle = {
//     width: "33px",
//     height: "55",
// };

// const columns = [
// const Checkbox2 = React.forwardRef(({ onClick, ...rest }, ref) => {
//     return (
//         <>
//             <div className="form-check pb-5" id="divTable" style={{ backgroundColor: '', display: 'flex', textAlign: 'center', justifyContent: 'center' }}>
//                 <Form.Check
//                     height={66}
//                     style={mystyle}
//                     ref={ref}
//                     onClick={onClick}
//                     {...rest}
//                 />

//             </div>
//         </>
//     )
// })

//     { field: "notes2", name: "المادة", options: { filter: true, sort: false, } },
//     { field: "notes", name: "ملاحظات", options: { filter: true, sort: true, } },

//     { field: "price", name: "السعر", options: { width: 25, filter: true, sort: true, } },
//     { field: "address", name: "العنوان", options: { width: 350, filter: true, sort: true, } },
//     { field: "phone", name: "رقم الهاتف", options: { filter: true, sort: true, } },

//     { field: "id", name: "رقم الوصل", options: { width: 150, filter: true, sort: false, } },
//     //   { field: "i", name: "#", options: { filter: true, sort: false, } },

// ];
const customStyles = {

    rows: {
        style: {
            minHeight: '72px', // override the row height
            border: 'solid 1px black'

        },
    },

    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
            justifyContent: 'center',
            ' font-size': '25px',
            'font-weight': 'bold',
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
            border: 'solid 1px black',
            ' font-size': '20px',
            'font-weight': 'bold',
            justifyContent: 'center',
        },
    },
};
const conditionalRowStyles = [
    {
        when: row => row.seleced,
        style: {
            backgroundColor: "#2E86C1",
            userSelect: "none",
            color: 'white'
        }
    }
];

const columns = [
    {
        name: 'المادة',
        selector: row => row.notes2,
        sortable: true,
        
        cell: row => <div style={{ textAlign:"center",fontSize: 15 }}>{row.notes2}</div>


    },
    {
        name: 'ملاحظات',
        selector: row => row.notes,
        sortable: true,
        width: "225px",
        cell: row => <div style={{  textAlign:"center",fontSize: 15 }}>{row.notes}</div>

    },
    {
        name: 'السعر',
        selector: row => row.price,
        width: "80px",
    },
    {
        name: 'العنوان',
        selector: row => row.government + " / " + row.city,
        sortable: true,
        width: "225px",
        textAlign:"center",
        cell: row => <div style={{ fontSize: 15,textAlign:"center" }}>{row.government + " / " + row.city}</div>

    },
    {
        name: 'رقم الهاتف',
        selector: row => row.phone,
        width: "200px",
        
    },
    {
        name: ' الوصل',
        selector: row => row.order_number,
        sortable: true,
        width: "150px",
    },
];

const cookies = new Cookies();
let id = 0
class Selles_Reports extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            orders: [],
            from: new Date(),
            to: new Date(),
            spin: false,
            serch: false,
            total: '',
            total_items: 0,
            total_orders: 0,
            items_cost: 0,
            orders_cost: 0,
            ordes2: [],
            id: '',
            ids: [],
            selectedData: []
        }
        var header = { "Content-Type": "application/json", token: cookies.get("token") };
        axios.get(host + `dashbord/profile`, { headers: header })
        .then((response) => {
            var a = moment(response.data.data.profile.license);
            var b = moment(new Date());
            a.diff(b, 'days');

            if (a.diff(b, 'days') < 0) {
                window.location.href = '/expired';
            }
        
            // this.setState({ items: response.data.data.data })
        }).catch((error) => {
            window.location.href = '/login';

        })
        this.Orders()

    }
    rowSelect(data) {

        var Data = data.data;
        var row = data.rowsSelected;

        let ids = []
        for (let index = 0; index < row.length; index++) {
            let id = Data[index].data.id

            ids.push(id)
        }
        this.setState({ ids })
        // for (let index = 0; index < row.length; index++) {
        //     const element = array[index];

        // } 
        // this.setState({
        //     TableIndex: row,
        //     TableData: Data,
        //     button: true,
        // })
    }
    handleRowClicked(row) {

        let data = this.state.orders
        console.log(row);
        for (let index = 0; index < data.length; index++) {

            if (data[index].id === row.id) {
                data[index].seleced = !data[index].seleced
            }

        }

        this.setState({ orders: data })
    }




    getMuiTheme = () => createMuiTheme({
        overrides: {
            MaterialDatatableBodyCell: {
                // root: {
                //     //   backgroundColor: "#FF0000",
                //     textAlign: 'center',
                //     fontSize: '25px',
                //     fontWeight: 'bold',
                //     border: '2px solid'

                // },

            },

            MuiTableCell: {
                root: {
                    textAlign: 'right',

                },
                // head: {
                //     // backgroundColor: "#FF0000",
                //     textAlign: 'center',
                //     fontWeight: 'bold',
                //     fontSize: '25px',
                //     border: '2px solid'
                // }
            },

        }
    })

    Orders() {

        var header = { "Content-Type": "application/json", token: cookies.get("token") };

        axios.get(host + `users/orders/prints/all`, { headers: header })
            .then(res => {
                let arr = [];
                let items = res.data.data.orders

                let ordes2 = []
                for (let i = 0; i < items.length; i++) {

                    items[i].address = items[i].government + "/" + items[i].city;
                    items[i].seleced = false;

                    arr.push(items[i]);

                }


                this.setState({
                    orders_count: items.length,
                    orders: items,
                    spin: false,
                    total_items: res.data.data.items_sum,
                    total_orders: res.data.data.order_count,
                    items_cost: res.data.data.items_cost,
                    orders_cost: res.data.data.sells,
                    ordes2
                });
            })
            .catch(error => {
                console.log(error.response)
            });
    }
    handleChangeFrom = date => {
        console.log(date);

        this.setState({
            from: date
        });
    };
    handleChangeTo = date => {
        this.setState({
            to: date
        });
    };
    download() {
        const data = this.state.ordes2
        const fileName = `${moment(new Date()).format('YYYY-MM-DD')}/${id}`
        const exportType = exportFromJSON.types.xls

        exportFromJSON({ data, fileName, exportType })
    }
    handleRowClicked = row => {

        const updatedData = this.state.orders.map(item => {
            if (row.id !== item.id) {
                return item;
            }

            return {
                ...item,
                seleced: !item.seleced
            };
        });
        let selectditems = this.state.selectedData;
        let check = this.state.selectedData.find((item) => item.id === row.id)
        if (check) {
            selectditems = this.state.selectedData.filter((item) => item.id !== row.id)
        } else {
            selectditems.push(row)
        }

        this.setState({ orders: updatedData, selectedData: selectditems })
        // setData(updatedData);
    };


    render() {
        if (cookies.get("token")) {
            return (
                <Context.Consumer>
                    {ctx => {
                        // if (this.state.spin) {
                        return (
                            <div >




                                <br />
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                                    <div style={{ textAlign: 'left', marginLeft: '15px' }}>

                                    </div>
                                    <div style={{ textAlign: 'right', marginRight: '15px' }}>
                                        <Component initialState={{ isShown: false, items: [], notes: "", spin: true, check: 0 }}>
                                            {({ state, setState }) => (
                                                <Pane>
                                                    <Dialog
                                                        isShown={state.isShown}
                                                        onCloseComplete={() => {
                                                            setState({ isShown: false, check: 0 })

                                                        }}
                                                        confirmLabel=" طباعة"

                                                        intent="success"
                                                        hasFooter={true}
                                                        title={" طباعة وصولات"}
                                                        onConfirm={() => {
                                                            if (state.check === 0) {
                                                                setState({ check: 1 })
                                                                var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
                                                                let formData = new FormData();
                                                                for (let i = 0; i < this.state.selectedData.length; i++) {
                                                                    formData.append("orders_id", this.state.selectedData[i].id);

                                                                }

                                                                formData.append("name", state.notes);
                                                                axios({ url: host + `users/orders/prints`, method: "POST", data: formData, headers: header })
                                                                    .then(response => {

                                                                        window.location.href=(`Print2/${response.data.data.print.id}`);


                                                                    })
                                                                    .catch(error => { console.log(error) })
                                                            } else {
                                                                setState({ isShown: false })
                                                                setState({ check: 0 })
                                                            }


                                                            // setTimeout(() => {
                                                            //     this.print()

                                                            // }, 200);
                                                        }}
                                                    >
                                                        <div style={{ direction: 'rtl', textAlign: 'right' }} >
                                                            <Label > اسم المندوب</Label>
                                                            <Textarea id="notes" value={state.notes} name="textarea-1" placeholder="اسم المندوب"
                                                                onChange={(e) => {

                                                                    setState({ notes: e.target.value })
                                                                }} />
                                                        </div>
                                                    </Dialog>
                                                    <Button marginLeft={30} marginTop={5} disabled={this.state.selectedData.length > 0 ? false : true} appearance="primary"
                                                        iconBefore={LocalPrintshopIcon}
                                                        onClick={() => {
                                                            setState({ isShown: true, spin: true })
                                                        }}
                                                        height={45}
                                                    >
                                                        طباعة {this.state.selectedData.length} وصل
                                                    </Button>
                                                </Pane>
                                            )}
                                        </Component>





                                        <br></br>
                                        <br></br>
                                        <br></br>
                                    </div>
                                </div>


                                <div>
                                    <div className='DataTableContiner'>
                                        <DataTable title="طباعة وصلات" highlightOnHover
                                            pointerOnHover direction={'center'}
                                            conditionalRowStyles={conditionalRowStyles}
                                            customStyles={customStyles}
                                            columns={columns}
                                            data={this.state.orders}
                                            //   selectableRows
                                            onRowClicked={this.handleRowClicked}

                                            onSelectedRowsChange={({ allSelected, selectedCount, selectedRows }) => {
                                                //   console.log(selectedRows);
                                                this.setState({ selectedData: selectedRows })


                                            }}
                                        //    selectableRowsComponent={Checkbox2}
                                        // onRowClicked={(row) => {

                                        //     this.handleRowClicked(row)
                                        // }}

                                        />

                                    </div>

                                </div>

                                <ToastContainer
                                    position="bottom-left"
                                    autoClose={10000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnVisibilityChange
                                    draggable
                                    pauseOnHover
                                />
                            </div>
                        )
                        // }
                        // else {
                        //     return (
                        //         <div style={{ width: '100%', height: 'calc(100vh - 60px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        //             <Lottie
                        //                 options={{
                        //                     animationData: animation
                        //                 }}
                        //             />
                        //         </div>
                        //     )
                        // }
                    }
                    }
                </Context.Consumer>
            )
        }
        else {
            return (
                <Login />
            )
        }
    }
}


export default Selles_Reports;