import React from 'react';
import Context from '../../assets/js/Context';
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import axios from 'axios';

import "react-datepicker/dist/react-datepicker.css";
import Component from "@reactions/component";
import { SideSheet, Position} from 'evergreen-ui';
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';

// import Lottie from 'lottie-react-web';
// import animation from '../../assets/json/clean.json';
import 'react-toastify/dist/ReactToastify.css';
import MaterialDatatable from "material-datatable";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

import qs from 'qs';


import Login from '../common/login';
const columns = [
    // { field: "delete", name: "حذف", options: { width: 60, filter: true, sort: false, } },
    //   { field: "date", name: "تاريخ الاضافة ", options: { filter: true, sort: false, } },
    // { field: "add_date", name: " ", options: { filter: true, sort: false, } },

 //   { field: "edit", name: "تعديل", options: { filter: true, sort: false, } },
    { field: "email", name: "اسم المستخدم", options: { filter: true, sort: false, } },

    { field: "role", name: " الصلاحيات", options: { filter: true, sort: false, } },

    { field: "name", name: "اسم البيج ", options: { filter: true, sort: false, } },

    { field: "id", name: "#", options: { width: 200, filter: true, sort: false, } },
];
const options = {
    selectableRows: false,
    responsive: 'scroll',
    rowCursorHand: false,
    sort: false,
    print: false,
    filter: false,
    download: false,
    textLabels: {
        body: {
            noMatch: " لم يتم العثور على سجلات مطابقة",
            toolTip: "فرز",
        },
        pagination: {
            next: "الصفحة التالية",
            previous: "الصفحة السابقة",
            rowsPerPage: "عدد الصفوف",
            displayRows: "من",
        },
        toolbar: {
            search: "بحث",
            downloadCsv: "تنزيل",
            print: "Print",
            viewColumns: " التحكم بالاعمدة",
            filterTable: "فلتر",
        },
        filter: {
            all: "الكل",
            title: "فلتر",
            reset: "إعادة تعيين",
        },
        viewColumns: {
            title: "عرض الأعمدة",
            titleAria: "إظهار / إخفاء أعمدة الجدول",
        },

    }
}

const cookies = new Cookies();

class Category extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            orders: [],

            spin: false,
            serch: false,
            total: ''
        }


    }
    getMuiTheme = () => createMuiTheme({
        overrides: {
            MaterialDatatableBodyCell: {
                root: {
                    //   backgroundColor: "#FF0000",
                    textAlign: 'right',
                },

            },
            MuiTableCell: {
                root: {
                    textAlign: 'right',

                },
                head: {
                    // backgroundColor: "#FF0000",
                }
            },

        }
    })
    DeleteCate(id) {
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        axios.delete(host + `dashbord/spending/${id}?`, { headers: header })
            .then(response => {
                toast('تم الحذف بنجاح', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
                this.Orders();
            })
            .catch(error => { console.log(error) })
    }
    componentDidMount() {

        var header = { "Content-Type": "application/json", token: cookies.get("token") };

        axios.get(host + `dashbord/admins`, { headers: header })
            .then(res => {
                let arr = [];

                for (let i = 0; i < res.data.length; i++) {
                    let obj = {
                        id: res.data[i].id,
                        name: res.data[i].name,
                        role: '',
                        email: res.data[i].email,

                        edit: <Component initialState={{ isShown: false, nameUser: res.data[i].name, phoneUser: res.data[i].phone }}>
                            {({ state, setState }) => (
                                <React.Fragment>
                                    <SideSheet
                                        isShown={state.isShown}
                                        position={Position.LEFT}
                                        onCloseComplete={() => setState({ isShown: false })}
                                    >
                                        <div margin={40}>
                                            <div id='titleAddUser' style={{ backgroundColor: '#000' }}> تعديل البيج</div>
                                            <div id='inputAdd2Continer' >
                                                <div id={'ContinerInPut'} >
                                                    <label>اسم البيج</label>
                                                    <input autoComplete='off' type='text' placeholder='اسم البيج' value={state.nameUser} id='InputTExtDash1'
                                                        onChange={(e) => setState({ nameUser: e.target.value })} />
                                                </div>
                                                <div id={'ContinerInPut'} >
                                                    <label>رقم الهاتف</label>
                                                    <input autoComplete='off' type='number' value={state.phoneUser} placeholder='رقم الهاتف' id='InputTExtDash1'
                                                        onChange={(e) => setState({ phoneUser: e.target.value })} />
                                                </div>

                                            </div>
                                            <div id='btnAddContiner'>
                                                <div id='BtnAddUser' style={{ backgroundColor: '#000' }} onClick={() => {
                                                    axios.post(host + `dashbord/pages/edit/${res.data.pages[i].id}`,
                                                        qs.stringify({ name: state.nameUser, phone: state.phoneUser }), {
                                                        headers: {
                                                            "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token")
                                                        }
                                                    })
                                                        .then(response => {
                                                            toast('تمت الاضافة بنجاح', {
                                                                position: "bottom-center",
                                                                autoClose: 5000,
                                                                hideProgressBar: false,
                                                                closeOnClick: true,
                                                                pauseOnHover: true,
                                                                draggable: true
                                                            });

                                                        })
                                                        .catch(error => {
                                                            this.setState({ spinerBtn: false })
                                                        })
                                                    setState({ isShown: false })
                                                }}>تعديل</div>
                                            </div>

                                        </div>
                                    </SideSheet>
                                    <EditIcon style={{ color: '#007bff', cursor: 'pointer' }} onClick={() => setState({ isShown: true })}> </EditIcon>
                                </React.Fragment>
                            )}
                        </Component>

                    };
                    if (res.data[i].role === 1) {
                        obj.role = 'مسؤول'
                    } else {
                        obj.role = 'موظف'
                    }

                    arr.push(obj);
                }
                this.setState({
                    orders: arr, spin: false, total: 0
                });
            })
            .catch(error => {
            });
    }
    handleChangeFrom = date => {

        this.setState({
            from: date
        });
    };
    handleChangeTo = date => {
        this.setState({
            to: date
        });
    };
    render() {
        if (cookies.get("token")) {
            return (
                <Context.Consumer>
                    {ctx => {
                        // if (this.state.spin) {
                        return (
                            <div >
                                <div id='navDashContiner'>
                                    <Link to={'/SettingDash'} id='BtnAddUser' style={{ backgroundColor: '#fff', color: '#000', height: 30, margin: 20 }} > رجوع</Link>

                                    <Component initialState={{ isShown: false, nameUser: '', name: '', role: 0, phoneUser: '', passwordUser: '', }}>
                                        {({ state, setState }) => (
                                            <React.Fragment>
                                                <SideSheet
                                                    isShown={state.isShown}
                                                    position={Position.LEFT}
                                                    onCloseComplete={() => setState({ isShown: false })}
                                                >
                                                    <div margin={40}>
                                                        <div id='titleAddUser' style={{ backgroundColor: '#000' }}>اضافة مسؤول</div>
                                                        <div id='inputAdd2Continer' >
                                                            <div id={'ContinerInPut'} >
                                                                <label>اسم الشخصي</label>
                                                                <input autoComplete='off' type='text' placeholder='اسم الشخصي' id='InputTExtDash1'
                                                                    onChange={(e) => setState({ name: e.target.value })} />
                                                            </div>
                                                            <div id={'ContinerInPut'} >
                                                                <label>user_name</label>
                                                                <input autoComplete='off' type='text' placeholder='الاسم المستخدم لستجيل الدخول ' id='InputTExtDash1'
                                                                    onChange={(e) => setState({ nameUser: e.target.value })} />
                                                            </div>
                                                            <div id={'ContinerInPut'} >
                                                                <label>كلمة المرور</label>
                                                                <input autoComplete='off' type='text' placeholder='كلمة المرور' id='InputTExtDash1'
                                                                    onChange={(e) => setState({ passwordUser: e.target.value })} />
                                                            </div>

                                                            <div id={'ContinerInPut'} >
                                                                <label>دور المستخدم </label>
                                                                <select name="cars" id='InputTExtDash1' onChange={(e) => {
                                                                    setState({ role: e.target.value })
                                                                }}>
                                                                    <option value="0">موظف اعتيادي</option>
                                                                    <option value="1">مسؤول</option>

                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div id='btnAddContiner'>
                                                            <div id='BtnAddUser' style={{ backgroundColor: '#000' }} onClick={() => {
                                                                axios.post(host + `dashbord/admins/new`,
                                                                    qs.stringify({ name: state.name, email: state.nameUser, role: state.role, password: state.passwordUser }), {
                                                                    headers: {
                                                                        "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token")
                                                                    }
                                                                })

                                                                    .then(response => {
                                                                        toast('تمت الاضافة بنجاح', {
                                                                            position: "bottom-center",
                                                                            autoClose: 5000,
                                                                            hideProgressBar: false,
                                                                            closeOnClick: true,
                                                                            pauseOnHover: true,
                                                                            draggable: true
                                                                        });
                                                                        this.componentDidMount()
                                                                    })
                                                                    .catch(error => {
                                                                        toast.error(error.response.data.message, {
                                                                            position: "bottom-center",
                                                                            autoClose: 5000,
                                                                            hideProgressBar: false,
                                                                            closeOnClick: true,
                                                                            pauseOnHover: true,
                                                                            draggable: true
                                                                        });
                                                                        this.setState({ spinerBtn: false })
                                                                    })
                                                                setState({ isShown: false })
                                                            }}>اضافة</div>
                                                        </div>

                                                    </div>
                                                </SideSheet>
                                                <div id='BtnAddUser' style={{ backgroundColor: '#fff', color: '#000', height: 30, margin: 20 }} onClick={() => setState({ isShown: true })}>اضافة مسؤول </div>
                                            </React.Fragment>
                                        )}
                                    </Component>

                                </div>


                                <div>
                                    <div className='DataTableContiner'>
                                        <MuiThemeProvider
                                            theme={this.getMuiTheme()}>
                                            <MaterialDatatable data={this.state.orders} columns={columns} options={options} />
                                        </MuiThemeProvider>
                                    </div>

                                </div>
                                <ToastContainer
                                    position="bottom-left"
                                    autoClose={10000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnVisibilityChange
                                    draggable
                                    pauseOnHover
                                />
                            </div>
                        )
                        // }
                        // else {
                        //     return (
                        //         <div style={{ width: '100%', height: 'calc(100vh - 60px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        //             <Lottie
                        //                 options={{
                        //                     animationData: animation
                        //                 }}
                        //             />
                        //         </div>
                        //     )
                        // }
                    }
                    }
                </Context.Consumer>
            )
        }
        else {
            return (
                <Login />
            )
        }
    }
}


export default Category;