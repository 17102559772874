import React from 'react';
import Context from '../../assets/js/Context';
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import axios from 'axios';

import "react-datepicker/dist/react-datepicker.css";
import Component from "@reactions/component";
import { SideSheet, Position, Pane, Dialog } from 'evergreen-ui';
import { ToastContainer, toast } from 'react-toastify';

import { Link } from 'react-router-dom';

import InfoIcon from '@material-ui/icons/Info';
// import Lottie from 'lottie-react-web';
// import animation from '../../assets/json/clean.json';
import 'react-toastify/dist/ReactToastify.css';
import MaterialDatatable from "material-datatable";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import qs from 'qs';
import DesktopAccessDisabledIcon from '@material-ui/icons/DesktopAccessDisabled';
import Login from '../common/login';
import DesktopMacIcon from '@material-ui/icons/DesktopWindows';
import moment from 'moment/moment';
const columns = [
    // { field: "delete", name: "حذف", options: { width: 60, filter: true, sort: false, } },
    //   { field: "date", name: "تاريخ الاضافة ", options: { filter: true, sort: false, } },
    // { field: "add_date", name: " ", options: { filter: true, sort: false, } },
    { field: "logo", name: "logo", options: { filter: true, sort: false, } },

    { field: "createdAt", name: "تاريخ الاشتراك", options: { filter: true, sort: false, } },
    { field: "isActive", name: "الحالة", options: { filter: true, sort: false, } },




    { field: "license", name: "الصلاحية", options: { filter: true, sort: false, } },

    { field: "phone", name: "رقم الهاتف ", options: { filter: true, sort: false, } },
    { field: "name", name: "الاسم", options: { filter: true, sort: true, } },
    { field: "id", name: "#", options: { width: 200, filter: true, sort: false, } },
];
const options = {
    selectableRows: false,
    responsive: 'scroll',
    rowCursorHand: false,
    sort: false,
    print: false,
    filter: false,
    download: false,
    textLabels: {
        body: {
            noMatch: " لم يتم العثور على سجلات مطابقة",
            toolTip: "فرز",
        },
        pagination: {
            next: "الصفحة التالية",
            previous: "الصفحة السابقة",
            rowsPerPage: "عدد الصفوف",
            displayRows: "من",
        },
        toolbar: {
            search: "بحث",
            downloadCsv: "تنزيل",
            print: "Print",
            viewColumns: " التحكم بالاعمدة",
            filterTable: "فلتر",
        },
        filter: {
            all: "الكل",
            title: "فلتر",
            reset: "إعادة تعيين",
        },
        viewColumns: {
            title: "عرض الأعمدة",
            titleAria: "إظهار / إخفاء أعمدة الجدول",
        },

    }
}
const cookies = new Cookies();
let categories = []
class Category extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            orders: [],

            spin: false,
            serch: false,
            total: ''
        }



    }
    getMuiTheme = () => createMuiTheme({
        overrides: {
            MaterialDatatableBodyCell: {
                root: {
                    //   backgroundColor: "#FF0000",
                    textAlign: 'right',
                },

            },
            MuiTableCell: {
                root: {
                    textAlign: 'right',

                },
                head: {
                    // backgroundColor: "#FF0000",
                }
            },

        }
    })
    DeleteCate(id) {
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        axios.delete(host + `dashbord/user/active/${id}`, { headers: header })
            .then(response => {
                toast('تم الحذف بنجاح', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
                this.componentDidMount();
            })
            .catch(error => { console.log(error) })
    }
    componentDidMount() {

        var header = { "Content-Type": "application/json", token: cookies.get("token") };

        axios.get(host + `dashbord/accunts/all`, { headers: header })
            .then(res => {
                let arr = [];
                console.log(res.data);
                for (let index = 0; index < res.data.length; index++) {
                    let obj = {
                        id: res.data[index].id,
                        name: res.data[index].name,
                        phone: res.data[index].phone,
                        license: moment(res.data[index].license).format('DD/MM/YYYY'),
                        createdAt: moment(res.data[index].createdAt).format('DD/MM/YYYY'),
                        isActive: res.data[index].isActive,
                        logo: res.data[index].logo,
                    }
                    arr.push(obj)

                }
                this.setState({ orders: arr })
            })
            .catch(error => {
                console.log(error.response)
            });
    }
    handleChangeFrom = date => {
        console.log(date);

        this.setState({
            from: date
        });
    };
    handleChangeTo = date => {
        this.setState({
            to: date
        });
    };
    render() {
        return (
            <Context.Consumer>
                {ctx => {
                    // if (this.state.spin) {
                    return (
                        <div >
                            <div id='navDashContiner'>
                                <Link to={'/SettingDash'} id='BtnAddUser' style={{ backgroundColor: '#fff', color: '#000', height: 30, margin: 20 }} > رجوع</Link>

                                <Component initialState={{ isShown: false, nameUser: '', license: '', admin: '', phoneUser: '', passwordUser: '', logo: [] }}>
                                    {({ state, setState }) => (
                                        <React.Fragment>
                                            <SideSheet
                                                isShown={state.isShown}
                                                position={Position.LEFT}
                                                onCloseComplete={() => setState({ isShown: false })}
                                            >
                                                <div margin={40}>
                                                    <div id='titleAddUser' style={{ backgroundColor: '#000' }}>اضافة مشترك</div>
                                                    <div id='inputAdd2Continer' >
                                                        <div id={'ContinerInPut'} >
                                                            <label>الاسم</label>
                                                            <input autoComplete='off' type='text' placeholder='اسم الموظف' id='InputTExtDash1'
                                                                onChange={(e) => setState({ nameUser: e.target.value })} />
                                                        </div>
                                                        <div id={'ContinerInPut'} >
                                                            <label>رقم الهاتف</label>
                                                            <input autoComplete='off' type='number' placeholder='رقم الهاتف' id='InputTExtDash1'
                                                                onChange={(e) => setState({ phoneUser: e.target.value })} />
                                                        </div>
                                                        <div id={'ContinerInPut'} >
                                                            <label>كلمه المرور</label>
                                                            <input autoComplete='off' type='text' placeholder='كلمه المرور' id='InputTExtDash1'
                                                                onChange={(e) => setState({ passwordUser: e.target.value })} />
                                                        </div>
                                                        <div id={'ContinerInPut'} >
                                                            <label> اسم المستخدم</label>
                                                            <input autoComplete='off' type='text' placeholder='اسم تسجيل الدخول' id='InputTExtDash1'
                                                                onChange={(e) => setState({ admin: e.target.value })} />
                                                        </div>
                                                        <div id={'ContinerInPut'} >
                                                            <label>الصلاحية</label>
                                                            <input autoComplete='off' type='date' placeholder='اسم تسجيل الدخول' id='InputTExtDash1'
                                                                onChange={(e) => setState({ license: e.target.value })} />
                                                        </div>
                                                        <div id={'ContinerInPut'} >
                                                            <label>logo</label>
                                                            <input autoComplete='off' type='file' placeholder='اسم تسجيل الدخول' id='InputTExtDash1'
                                                                onChange={(e) => setState({ logo: e.target.files })} />
                                                        </div>
                                                    </div>
                                                    <div id='btnAddContiner'>
                                                        <div id='BtnAddUser' style={{ backgroundColor: '#000' }} onClick={() => {

                                                            console.log(state.nameUser, state.license, state.admin, state.passwordUser, state.phoneUser, state.logo);
                                                            var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
                                                            let formData = new FormData();
                                                            formData.append("name", state.nameUser);
                                                            formData.append("license", state.license);
                                                            formData.append("admin", state.admin);
                                                            formData.append("password", state.passwordUser);
                                                            formData.append("phone", state.phoneUser);
                                                            formData.append("file", state.logo[0]);
                                                            axios({ url: host + `dashbord/account/add`, method: "POST", data: formData, headers: header })
                                                                .then(response => {
                                                                    this.setState({ name: '' ,license: '',admin:"" ,password:"",phone:"",file:[],})
                                                                    
                                                                    this.componentDidMount()
                                                                    setState({ isShown: false })
                                                                    toast('تم اضافة المشترك بنجاح', {
                                                                        position: "bottom-center",
                                                                        autoClose: 5000,
                                                                        hideProgressBar: false,
                                                                        closeOnClick: true,
                                                                        pauseOnHover: true,
                                                                        draggable: true
                                                                    });
                                                                })
                                                                .catch(error => {
                                                                    toast.error(error.response.data.message, {
                                                                        position: "bottom-center",
                                                                        autoClose: 5000,
                                                                        hideProgressBar: false,
                                                                        closeOnClick: true,
                                                                        pauseOnHover: true,
                                                                        draggable: true
                                                                    });
                                                          

                                                                })


                                                            // axios.post(host + `dashbord/users/add`,
                                                            //     qs.stringify({name: state.nameUser, Percentage: state.Percentage, password: state.passwordUser, phone: state.phoneUser }), {
                                                            //     headers: {
                                                            //         "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token")
                                                            //     }
                                                            // })
                                                            //     .then(response => {
                                                            //         this.componentDidMount()
                                                            //         toast('تمت الاضافة بنجاح', {
                                                            //             position: "bottom-center",
                                                            //             autoClose: 5000,
                                                            //             hideProgressBar: false,
                                                            //             closeOnClick: true,
                                                            //             pauseOnHover: true,
                                                            //             draggable: true
                                                            //         });
                                                            //         this.componentDidMount()
                                                            //     })
                                                            //     .catch(error => {
                                                            //         toast.error(error.response.data.message, {
                                                            //             position: "bottom-center",
                                                            //             autoClose: 5000,
                                                            //             hideProgressBar: false,
                                                            //             closeOnClick: true,
                                                            //             pauseOnHover: true,
                                                            //             draggable: true
                                                            //         });
                                                            //         this.setState({ spinerBtn: false })
                                                            //     })
                                                            // setState({ isShown: false })
                                                        }}>اضافة</div>
                                                    </div>

                                                </div>
                                            </SideSheet>
                                            <div id='BtnAddUser' style={{ backgroundColor: '#fff', color: '#000', height: 30, margin: 20 }} onClick={() => setState({ isShown: true })}>اضافة مشترك</div>
                                        </React.Fragment>
                                    )}
                                </Component>

                            </div>


                            <div>
                                <div className='DataTableContiner'>
                                    <MuiThemeProvider
                                        theme={this.getMuiTheme()}>
                                        <MaterialDatatable data={this.state.orders} columns={columns} options={options} />
                                    </MuiThemeProvider>
                                </div>

                            </div>
                            <ToastContainer
                                position="bottom-left"
                                autoClose={10000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnVisibilityChange
                                draggable
                                pauseOnHover
                            />
                        </div>
                    )
                    // }
                    // else {
                    //     return (
                    //         <div style={{ width: '100%', height: 'calc(100vh - 60px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    //             <Lottie
                    //                 options={{
                    //                     animationData: animation
                    //                 }}
                    //             />
                    //         </div>
                    //     )
                    // }
                }
                }
            </Context.Consumer>
        )
    }
}


export default Category;