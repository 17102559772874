import React from 'react';
import Context from '../../assets/js/Context';
import Component from "@reactions/component";
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import axios from 'axios';
import { SideSheet, Position } from 'evergreen-ui';
import qs from 'qs';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Login from '../common/login';
import { Button, Row, Col } from 'react-bootstrap';
import PeopleIcon from '@material-ui/icons/People';
import Storefront from '@material-ui/icons/Storefront';
import SettingsIcon from '@material-ui/icons/Settings';
import VpnKeyIcon from '@material-ui/icons/AccessibilityNew';
const cookies = new Cookies();

class Category extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nameCate: '',
            Category: [],
            spin: false,
        }
    }

    componentDidMount() {
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        axios.get(host + `dashbord/category`, { headers: header })
            .then(res => {
                this.setState({
                    Category: res, spin: true
                });
            })
            .catch(error => { console.log(error.response) })

    }

    render() {
        if (cookies.get("token")) {
            return (
                <Context.Consumer>
                    {ctx => {
                        // if (this.state.spin) {
                        return (
                            <div >
              
                                <Row >
                                    <Col sm={12} lg={6} xl={3} style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: 30 }}>
                                        <Button style={{ borderRadius: "15px", backgroundColor: "#2E4053" }} href="/Employee" variant="primary" size="lg">
                                            <div id="settingDivs">
                                                <h4>   الموظفين</h4>
                                                <PeopleIcon style={{ fontSize: "40px" }}></PeopleIcon>
                                            </div>
                                        </Button>
                                    </Col>
                                    <Col sm={12} lg={6} xl={3} style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: 30 }}>
                                        <Button style={{ borderRadius: "15px", backgroundColor: "#138D75" }} href="/pages" variant="primary" size="lg">
                                            <div id="settingDivs">
                                                <h4>   البيجات</h4>
                                                <Storefront style={{ fontSize: "40px" }}></Storefront>
                                            </div>
                                        </Button>
                                    </Col>
                                    <Col sm={12} lg={6} xl={3} style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: 30 }}>

                                        <Button style={{ borderRadius: "15px", backgroundColor: "#CA6F1E" }} href="/GeneralSetting" variant="primary" size="lg">
                                            <div id="settingDivs">
                                                <h4>   الاعدادت</h4>
                                                <SettingsIcon style={{ fontSize: "40px" }}></SettingsIcon>
                                            </div>
                                        </Button>
                                    </Col>

                                    <Col sm={12} lg={6} xl={3} style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: 30 }}>
                                        <Button style={{ borderRadius: "15px", backgroundColor: "#3498DB" }} href="/admins" variant="primary" size="lg">
                                            <div id="settingDivs">
                                                <h4>   المسؤولين </h4>
                                                <VpnKeyIcon style={{ fontSize: "40px" }}></VpnKeyIcon>
                                            </div>
                                        </Button>
                                    </Col>


                                </Row>
                                {/* <Row>
                                    <Col sm={12} lg={6} xl={3} style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: 30 }}>
                                        <Button style={{ borderRadius: "15px", backgroundColor: "#3498DB" }} href="/chars" variant="primary" size="lg">
                                            <div id="settingDivs">
                                                <h4>   التقارير الرسومية </h4>
                                                <VpnKeyIcon style={{ fontSize: "40px" }}></VpnKeyIcon>
                                            </div>
                                        </Button>
                                    </Col>
                                </Row> */}
                                <ToastContainer
                                    position="bottom-left"
                                    autoClose={10000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnVisibilityChange
                                    draggable
                                    pauseOnHover
                                />
                            </div>
                        )
                        // }
                        // else {
                        //     return (
                        //         <div style={{ width: '100%', height: 'calc(100vh - 60px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        //             <Lottie
                        //                 options={{
                        //                     animationData: animation
                        //                 }}
                        //             />
                        //         </div>
                        //     )
                        // }
                    }
                    }
                </Context.Consumer>
            )
        }
        else {
            return (
                <Login />
            )
        }
    }
}


export default Category;