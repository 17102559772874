import React from 'react';
import Context from '../../assets/js/Context';
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import axios from 'axios';
import Component from "@reactions/component";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { Pane, Dialog, Spinner, SideSheet, Position } from 'evergreen-ui';
import { ToastContainer, toast } from 'react-toastify';
// import animation from '../../assets/json/clean.json';
import 'react-toastify/dist/ReactToastify.css';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Login from '../common/login';
import * as moment from 'moment'

const cookies = new Cookies();

class Category extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nameCate: '',
            image: [],
            Category: [],
            spin: false,
        }
    }

    componentDidMount() {
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        axios.get(host + `dashbord/category`, { headers: header })
            .then(res => {
             
                var arr = []
                for (let i = 0; i < res.data.data.categories.length; i++) {
                    if (res.data.data.categories[i].is_count) {
                        arr.push(res.data.data.categories[i])
                    }
                }
                this.setState({
                    Category: arr, spin: true
                });
            })
            .catch(error => { console.log(error.response) })
          
            axios.get(host + `dashbord/profile`, { headers: header })
            .then((response) => {
                var a = moment(response.data.data.profile.license);
                var b = moment(new Date());
                a.diff(b, 'days');

                if (a.diff(b, 'days') < 0) {
                    window.location.href = '/expired';
                }
            
                // this.setState({ items: response.data.data.data })
            }).catch((error) => {
                window.location.href = '/login';

            })
    }
    EditCate(id) {
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        let formData = new FormData();
        formData.append("name", this.state.nameCate);
        formData.append("is_count", 1);
        formData.append("file", this.state.image[0]);
        axios({ url: host + `dashbord/category/${id}`, method: "PUT", data: formData, headers: header })
            .then(response => {
                this.setState({ image: '' })
                this.componentDidMount()
                toast('تم التعديل بنجاح', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            })
            .catch(error => { console.log(error.response.data.message) })
    }
    DeleteCate(id) {
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        axios.delete(host + `dashbord/category/${id}?`, { headers: header })
            .then(response => {
                toast('تم الحذف بنجاح', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
                this.componentDidMount();
            })
            .catch(error => { console.log(error.response.data.message) })
    }
    AddCate() {
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        let formData = new FormData();
        formData.append("name", this.state.nameCate);
        formData.append("is_count", 1);
        formData.append("file", this.state.image[0]);
        axios({ url: host + `dashbord/category`, method: "POST", data: formData, headers: header })
            .then(response => {
                this.componentDidMount();
                toast('تم اضافة القسم بنجاح', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            })
            .catch(error => { console.log(error.response.data.message) })
    }
    render() {
        if (cookies.get("token")) {
            return (
                <Context.Consumer>
                    {ctx => {
                        if (this.state.spin) {
                            return (
                                <div >
                                    <div id='navDashContiner'>
                                        <Component initialState={{ isShown: false }}>
                                            {({ state, setState }) => (
                                                <React.Fragment>
                                                    <SideSheet
                                                        isShown={state.isShown}
                                                        position={Position.LEFT}
                                                        onCloseComplete={() => setState({ isShown: false })}
                                                    >
                                                        <div margin={40}>
                                                            <div id='titleAddUser' style={{ backgroundColor: '#000' }}>اضافة قسم</div>
                                                            <div id='inputAdd2Continer' >
                                                                <div id={'ContinerInPut'} >
                                                                    <label>اسم القسم </label>
                                                                    <input autoComplete='off' type='text' placeholder='اسم القسم' id='InputTExtDash111'
                                                                        onChange={(e) => this.setState({ nameCate: e.target.value })} />
                                                                </div>
                                                                <div id={'ContinerInPut'}>
                                                                    <label>صورة</label>
                                                                    <input type='file'
                                                                        id='InputTExtDash111Image'
                                                                        onChange={file => {
                                                                            console.log(file.target.files);
                                                                            this.setState({ image: file.target.files })
                                                                        }}
                                                                        placeholder="Select the file "
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div id='btnAddContiner'>
                                                                <div id='BtnAddUser' style={{ backgroundColor: '#000' }} onClick={() => {
                                                                    this.AddCate()
                                                                    setState({ isShown: false })
                                                                }}>اضافة</div>
                                                            </div>

                                                        </div>
                                                    </SideSheet>
                                                    <div id='BtnAddUser' style={{ backgroundColor: '#fff', color: '#000', height: 30, margin: 20 }} onClick={() => setState({ isShown: true })}>اضافة قسم</div>
                                                </React.Fragment>
                                            )}
                                        </Component>

                                    </div>

                                    <Row style={{ margin: 0 }}>
                                        {this.state.Category.map((cate, i) =>
                                            <Col key={i} id='ColCateGory' xs={12} md={6} lg={3} >
                                                <Component initialState={{ spin: false, total: 1, menuOpen: false, editcount: false, edit: false, delete: false }}>
                                                    {({ state, setState }) => (
                                                        <React.Fragment>
                                                            <div id='imageCateContiner'>
                                                                <Link to={`/Items2Dash/${cate.id}`}>
                                                                    <img id='imageCateGory' src={host + 'files/' + cate.photo} alt='cate' />
                                                                    <h6 style={{ marginTop: 10, textAlign: 'center' }}>{cate.name}</h6>
                                                                </Link>
                                                                <div id='menuFolderCateContiner'>
                                                                    <div id='MenuBtnCateCont' onClick={() => setState({ menuOpen: !state.menuOpen })}>
                                                                        <MoreVertIcon />
                                                                    </div>
                                                                    <div id='menuEditAndDeleteContinerCate' style={state.menuOpen ? { display: 'flex' } : { display: 'none' }}>

                                                                        {/* /////////////////editcount////////////////////// */}
                                                                        {state.delete ? null :
                                                                            <React.Fragment>
                                                                                <SideSheet
                                                                                    isShown={state.isShown}
                                                                                    position={Position.LEFT}
                                                                                    onCloseComplete={() => setState({ isShown: false, editCount: false })}
                                                                                >

                                                                                    <React.Fragment>
                                                                                        <div margin={40}>
                                                                                            <div id='titleAddUser' style={{ backgroundColor: '#000' }}>تعديل القسم</div>
                                                                                            <div id='inputAdd2Continer' >
                                                                                                <div id={'ContinerInPut'} >
                                                                                                    <label>اسم القسم </label>
                                                                                                    <input autoComplete='off' type='text' placeholder='اسم القسم' id='InputTExtDash111'
                                                                                                        onChange={(e) => this.setState({ nameCate: e.target.value })} />
                                                                                                </div>
                                                                                                <div id={'ContinerInPut'}>
                                                                                                    <label>صورة</label>
                                                                                                    <input type='file'
                                                                                                        id='InputTExtDash111Image'
                                                                                                        onChange={file => {
                                                                                                            console.log(file.target.files);
                                                                                                            this.setState({ image: file.target.files })
                                                                                                        }}
                                                                                                        placeholder="Select the file "
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div id='btnAddContiner'>
                                                                                                <div id='BtnAddUser' style={{ backgroundColor: '#000' }} onClick={() => {
                                                                                                    this.EditCate(cate.id)

                                                                                                    setState({ isShown: false })
                                                                                                }}>تعديل</div>
                                                                                            </div>

                                                                                        </div>
                                                                                    </React.Fragment>


                                                                                </SideSheet>

                                                                                <div id='MenuBtnCateCont' onClick={() => {
                                                                                    this.setState({ spin2: true })
                                                                                    // this.getItemById(item.id)
                                                                                    setState({ isShown: true, edit: true })
                                                                                }}>
                                                                                    <EditIcon />
                                                                                </div>
                                                                            </React.Fragment>
                                                                        }
                                                                        <Pane>
                                                                            {state.delete ?
                                                                                <Dialog
                                                                                    isShown={state.isShown}
                                                                                    title="حذف مادة "
                                                                                    intent="danger"
                                                                                    onCloseComplete={() => setState({ isShown: false, delete: false })}
                                                                                    confirmLabel="تاكيد"
                                                                                    cancelLabel="الغاء"
                                                                                    onConfirm={() => {
                                                                                        setState({ isShown: false })
                                                                                        this.DeleteCate(cate.id)
                                                                                    }}
                                                                                >
                                                                                    <span id='msgDelete'> هل انت متاكد من عملية الحذف</span>
                                                                                </Dialog>
                                                                                : null}
                                                                            <div id='MenuBtnCateCont' onClick={() => setState({ isShown: true, menuOpen: false, delete: true })}>
                                                                                <DeleteIcon />
                                                                            </div>

                                                                        </Pane>
                                                                    </div>

                                                                </div>

                                                            </div>
                                                        </React.Fragment>
                                                    )}
                                                </Component>
                                            </Col>
                                        )}
                                    </Row>
                                    <ToastContainer
                                        position="bottom-left"
                                        autoClose={10000}
                                        hideProgressBar={false}
                                        newestOnTop={false}
                                        closeOnClick
                                        rtl={false}
                                        pauseOnVisibilityChange
                                        draggable
                                        pauseOnHover
                                    />
                                </div>
                            )
                        }
                        else {
                            return (
                                <div style={{ width: '100%', height: '100vh ', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Spinner />
                                </div>
                            )
                        }
                    }
                    }
                </Context.Consumer>
            )
        }
        else {
            return (
                <Login />
            )
        }
    }
}


export default Category;