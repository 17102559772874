import React from "react";
import Context from "../assets/js/Context.js";
import axios from "axios";
import "@kenshooui/react-multi-select/dist/style.css"
import Component from "@reactions/component";
import host from "../assets/js/Host";
import Cookies from "universal-cookie";
import { FaArrowCircleLeft } from "react-icons/fa";
import * as moment from 'moment'
import { Link } from 'react-router-dom';
import MaterialDatatable from "material-datatable";
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Pane, Spinner, Dialog } from 'evergreen-ui'
const cookies = new Cookies();
const columns = [
    { field: "count", name: "العدد", options: { filter: true, sort: true, } },
    { field: "price", name: "السعر", options: { filter: true, sort: true, } },
    { field: "name", name: "الاسم", options: { filter: true, sort: true, } },
    { field: "photo", name: "صوره", options: { width: 80, filter: true, sort: true, } },
];
const options = {
    selectableRows: false,
    responsive: 'scroll',
    rowCursorHand: false,
    sort: false,
    print: false,
    filter: false,
    download: false,
}

class Orders extends React.Component {
    constructor() {
        super();
        this.displayDataAdt = [];
        this.displayDataAdt2 = [];
        this.state = {
            items: [],
            selectedItems: [],
            spinPage: true,
        };

    }
    componentDidMount() {
        var header = { "Content-Type": "application/json", Authorization: cookies.get("token") };

        axios.get(host + `users/auth/profile`, { headers: header })
            .then((response) => {


                var a = moment(response.data.data.profile.license);
                var b = moment(new Date());
                a.diff(b, 'days');
         
                if (a.diff(b, 'days') < 0) {
                    window.location.href = '/expired';
                }
                if (response.data.data.profile.isBan === true) {
                    window.location.href = '/blocked';
                }
                // this.setState({ items: response.data.data.data })
            }).catch((error) => {
                window.location.href = '/login';

            })
        axios.get(host + `users/all/items/`, { headers: header })
            .then(res => {
                let arr = [];

                for (let i = 0; i < res.data.data.data.length; i++) {

                    let obj = {
                        photo: <Component initialState={{ isShown: false }}>
                            {({ state, setState }) => (
                                <Pane>
                                    <Dialog
                                        isShown={state.isShown}
                                        onCloseComplete={() => setState({ isShown: false })}
                                        hasFooter={false}
                                        hasHeader={false}
                                    >
                                        <img src={host + 'files/' + res.data.data.data[i].photo} alt='img' style={{ width: '100%', height: 'auto', borderRadius: 5 }} />

                                    </Dialog>
                                    <img src={host + 'files/' + res.data.data.data[i].photo} alt='img' style={{ width: 60, height: 60, borderRadius: 5 }}
                                        onClick={() => setState({ isShown: true })} />
                                </Pane>
                            )}
                        </Component>,
                        name: res.data.data.data[i].label,
                        price: res.data.data.data[i].price,
                        count: res.data.data.data[i].count,
                    };
                    arr.push(obj);
                }
                this.setState({
                    items: arr, spinPage: false
                });
            })
            .catch(error => {
                if (error.response.data.statusCode === 401) {
                    this.setState({ expire: true })
                }
                console.log(error.response.data)
            })
    }
    getMuiTheme = () => createTheme({
        overrides: {
            MaterialDatatableBodyCell: {
                root: {
                    //   backgroundColor: "#FF0000",
                    textAlign: 'center',
                },

            },
            MuiTableCell: {
                root: {
                    textAlign: 'center',

                },
                head: {
                    // backgroundColor: "#FF0000",
                    textTransform: 'capitalize',
                }
            },

        }
    })

    render() {

        return (
            <div>
                <Context.Consumer>
                    {ctx => {
                        return (
                            <div>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', height: 100, padding: 20 }}>

                                    <Link to='/'>
                                        <FaArrowCircleLeft style={{ color: '#000', cursor: 'pointer', fontSize: 30 }} />
                                    </Link>
                                    <h4>قائمة المنتجات</h4>
                                </div>

                                {!this.state.spinPage ?
                                    <div className='DataTableContiner'>
                                        <MuiThemeProvider
                                            theme={this.getMuiTheme()}>
                                            <MaterialDatatable data={this.state.items} columns={columns} options={options} />
                                        </MuiThemeProvider>
                                    </div>
                                    :
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '60vh', width: '100%' }}>
                                        <Spinner />
                                    </div>
                                }

                            </div>
                        )


                    }}
                </Context.Consumer>
            </div>
        );
    }
}

export default Orders;