import React from 'react';
import Context from '../../assets/js/Context';
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import axios from 'axios';

import "react-datepicker/dist/react-datepicker.css";
import Component from "@reactions/component";
import { SideSheet, Position, Pane, Dialog } from 'evergreen-ui';
import { ToastContainer, toast } from 'react-toastify';

import { Link } from 'react-router-dom';

import InfoIcon from '@material-ui/icons/Info';
// import Lottie from 'lottie-react-web';
// import animation from '../../assets/json/clean.json';
import 'react-toastify/dist/ReactToastify.css';
import MaterialDatatable from "material-datatable";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import qs from 'qs';
import DesktopAccessDisabledIcon from '@material-ui/icons/DesktopAccessDisabled';
import Login from '../common/login';
import DesktopMacIcon from '@material-ui/icons/DesktopWindows';
const columns = [
    // { field: "delete", name: "حذف", options: { width: 60, filter: true, sort: false, } },
    //   { field: "date", name: "تاريخ الاضافة ", options: { filter: true, sort: false, } },
    // { field: "add_date", name: " ", options: { filter: true, sort: false, } },
    { field: "disable", name: "تعطيل", options: { filter: true, sort: false, } },

    { field: "info", name: "التفاصيل", options: { filter: true, sort: false, } },
    { field: "isActive", name: "الحالة", options: { filter: true, sort: false, } },




    { field: "Percentage", name: "النسبة ", options: { filter: true, sort: false, } },

    { field: "phone", name: "رقم الهاتف ", options: { filter: true, sort: false, } },
    { field: "name", name: "الاسم", options: { filter: true, sort: true, } },
    { field: "id", name: "#", options: { width: 200, filter: true, sort: false, } },
];
const options = {
    selectableRows: false,
    responsive: 'scroll',
    rowCursorHand: false,
    sort: false,
    print: false,
    filter: false,
    download: false,
    textLabels: {
        body: {
            noMatch: " لم يتم العثور على سجلات مطابقة",
            toolTip: "فرز",
        },
        pagination: {
            next: "الصفحة التالية",
            previous: "الصفحة السابقة",
            rowsPerPage: "عدد الصفوف",
            displayRows: "من",
        },
        toolbar: {
            search: "بحث",
            downloadCsv: "تنزيل",
            print: "Print",
            viewColumns: " التحكم بالاعمدة",
            filterTable: "فلتر",
        },
        filter: {
            all: "الكل",
            title: "فلتر",
            reset: "إعادة تعيين",
        },
        viewColumns: {
            title: "عرض الأعمدة",
            titleAria: "إظهار / إخفاء أعمدة الجدول",
        },

    }
}
const cookies = new Cookies();
let categories = []
class Category extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            orders: [],

            spin: false,
            serch: false,
            total: ''
        }


        axios.get(host + `dashbord/category`)
            .then(res => {
                let arr = [];

                for (let i = 0; i < res.data.data.categories.length; i++) {

                    let obj = {
                        id: res.data.data.categories[i].id,
                        name: res.data.data.categories[i].name,

                    };
                    categories.push(obj);
                }

                this.setState({
                    orders: arr, spin: false, total: res.data.data.amount
                });
            })
            .catch(error => {
                console.log(error.response)
            });
    }
    getMuiTheme = () => createMuiTheme({
        overrides: {
            MaterialDatatableBodyCell: {
                root: {
                    //   backgroundColor: "#FF0000",
                    textAlign: 'right',
                },

            },
            MuiTableCell: {
                root: {
                    textAlign: 'right',

                },
                head: {
                    // backgroundColor: "#FF0000",
                }
            },

        }
    })
    DeleteCate(id) {
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        axios.delete(host + `dashbord/user/active/${id}`, { headers: header })
            .then(response => {
                toast('تم الحذف بنجاح', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
                this.componentDidMount();
            })
            .catch(error => { console.log(error) })
    }
    componentDidMount() {

        var header = { "Content-Type": "application/json", token: cookies.get("token") };

        axios.get(host + `dashbord/users/all`, { headers: header })
            .then(res => {
                let arr = [];

                for (let i = 0; i < res.data.data.users.length; i++) {

                    let obj = {
                        id: res.data.data.users[i].id,
                        name: res.data.data.users[i].name,
                        phone: res.data.data.users[i].phone,
                        Percentage: res.data.data.users[i].Percentage,



                        isActive: <FiberManualRecordIcon style={res.data.data.users[i].isBan === false ? { color: '#28a745' } : { color: '#dc3545' }} />,
                        info: <div><Link to={`/user?id=${res.data.data.users[i].id}`} style={{ color: '#007bff', cursor: 'pointer' }} > <InfoIcon /></Link></div>,
                        disable: <Component initialState={{ isShown: false, msg: "", intent: '' }}>
                            {({ state, setState }) => (
                                <Pane>
                                    <Dialog
                                        isShown={state.isShown}
                                        title={state.msg}
                                        intent={state.intent}
                                        onCloseComplete={() => setState({ isShown: false })}
                                        confirmLabel={state.msg}
                                        cancelLabel="الغاء"
                                        onConfirm={() => {
                                            setState({ isShown: false })
                                            this.DeleteCate(res.data.data.users[i].id)
                                        }}
                                    >
                                        <span id='msgDelete'> هل انت متأكد من عملية {state.msg}</span>
                                    </Dialog>
                                    <DesktopAccessDisabledIcon style={res.data.data.users[i].isBan === false ? { cursor: 'pointer', color: '#ec7373', } : { display: 'none' }}
                                        onClick={() => {

                                            setState({ intent: "danger", msg: "تعطيل", isShown: true })
                                        }} />
                                    <DesktopMacIcon style={res.data.data.users[i].isBan === true ? { cursor: 'pointer', color: '#28a745', } : { display: 'none' }}
                                        onClick={() => { setState({ intent: "success", msg: "تفعيل", isShown: true }) }} />
                                </Pane>
                            )}
                        </Component>

                    };
                    arr.push(obj);
                }
                this.setState({
                    orders: arr, spin: false, total: res.data.data.amount
                });
            })
            .catch(error => {
                console.log(error.response)
            });
    }
    handleChangeFrom = date => {
        console.log(date);

        this.setState({
            from: date
        });
    };
    handleChangeTo = date => {
        this.setState({
            to: date
        });
    };
    render() {
        if (cookies.get("token")) {
            return (
                <Context.Consumer>
                    {ctx => {
                        // if (this.state.spin) {
                        return (
                            <div >
                                <div id='navDashContiner'>
                                    <Link to={'/SettingDash'} id='BtnAddUser' style={{ backgroundColor: '#fff', color: '#000', height: 30, margin: 20 }} > رجوع</Link>

                                    <Component initialState={{ isShown: false, nameUser: '', Percentage: '', phoneUser: '', passwordUser: '', }}>
                                        {({ state, setState }) => (
                                            <React.Fragment>
                                                <SideSheet
                                                    isShown={state.isShown}
                                                    position={Position.LEFT}
                                                    onCloseComplete={() => setState({ isShown: false })}
                                                >
                                                    <div margin={40}>
                                                        <div id='titleAddUser' style={{ backgroundColor: '#000' }}>اضافة موظف</div>
                                                        <div id='inputAdd2Continer' >
                                                            <div id={'ContinerInPut'} >
                                                                <label>اسم الموظف</label>
                                                                <input autoComplete='off' type='text' placeholder='اسم الموظف' id='InputTExtDash1'
                                                                    onChange={(e) => setState({ nameUser: e.target.value })} />
                                                            </div>
                                                            <div id={'ContinerInPut'} >
                                                                <label>رقم الهاتف</label>
                                                                <input autoComplete='off' type='number' placeholder='رقم الهاتف' id='InputTExtDash1'
                                                                    onChange={(e) => setState({ phoneUser: e.target.value })} />
                                                            </div>
                                                            <div id={'ContinerInPut'} >
                                                                <label>كلمه المرور</label>
                                                                <input autoComplete='off' type='text' placeholder='كلمه المرور' id='InputTExtDash1'
                                                                    onChange={(e) => setState({ passwordUser: e.target.value })} />
                                                            </div>
                                                            <div id={'ContinerInPut'} >
                                                                <label> النسبة</label>
                                                                <input autoComplete='off' type='number' placeholder='نسبة المندوب ' id='InputTExtDash1'
                                                                    onChange={(e) => setState({ Percentage: e.target.value })} />
                                                            </div>
                                                        </div>
                                                        <div id='btnAddContiner'>
                                                            <div id='BtnAddUser' style={{ backgroundColor: '#000' }} onClick={() => {
                                                                axios.post(host + `dashbord/users/add`,
                                                                    qs.stringify({ name: state.nameUser, Percentage: state.Percentage, password: state.passwordUser, phone: state.phoneUser }), {
                                                                    headers: {
                                                                        "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token")
                                                                    }
                                                                })
                                                                    .then(response => {
                                                                        this.componentDidMount()
                                                                        toast('تمت الاضافة بنجاح', {
                                                                            position: "bottom-center",
                                                                            autoClose: 5000,
                                                                            hideProgressBar: false,
                                                                            closeOnClick: true,
                                                                            pauseOnHover: true,
                                                                            draggable: true
                                                                        });
                                                                        this.componentDidMount()
                                                                    })
                                                                    .catch(error => {
                                                                        toast.error(error.response.data.message, {
                                                                            position: "bottom-center",
                                                                            autoClose: 5000,
                                                                            hideProgressBar: false,
                                                                            closeOnClick: true,
                                                                            pauseOnHover: true,
                                                                            draggable: true
                                                                        });
                                                                        this.setState({ spinerBtn: false })
                                                                    })
                                                                setState({ isShown: false })
                                                            }}>اضافة</div>
                                                        </div>

                                                    </div>
                                                </SideSheet>
                                                <div id='BtnAddUser' style={{ backgroundColor: '#fff', color: '#000', height: 30, margin: 20 }} onClick={() => setState({ isShown: true })}>اضافة موظف</div>
                                            </React.Fragment>
                                        )}
                                    </Component>

                                </div>


                                <div>
                                    <div className='DataTableContiner'>
                                        <MuiThemeProvider
                                            theme={this.getMuiTheme()}>
                                            <MaterialDatatable data={this.state.orders} columns={columns} options={options} />
                                        </MuiThemeProvider>
                                    </div>

                                </div>
                                <ToastContainer
                                    position="bottom-left"
                                    autoClose={10000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnVisibilityChange
                                    draggable
                                    pauseOnHover
                                />
                            </div>
                        )
                        // }
                        // else {
                        //     return (
                        //         <div style={{ width: '100%', height: 'calc(100vh - 60px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        //             <Lottie
                        //                 options={{
                        //                     animationData: animation
                        //                 }}
                        //             />
                        //         </div>
                        //     )
                        // }
                    }
                    }
                </Context.Consumer>
            )
        }
        else {
            return (
                <Login />
            )
        }
    }
}


export default Category;