import React, { Component } from 'react'
import { Row, Col, Tab, Tabs } from 'react-bootstrap';
import { toaster, Spinner } from 'evergreen-ui';
import Context from '../../assets/js/Context';
import axios from 'axios';
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import Logo from "../../assets/img/logo.jpg"
// import Lottie from 'lottie-react-web'
// import animation from '../../assets/json/clean.json'
const cookies = new Cookies();

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            spinBtn: false,
        }
    }
    signINDash(e) {
        let user = document.getElementById('adminUserName').value;
        let password = document.getElementById('adminUserPassword').value;

        e.preventDefault()
        axios.post(host + `dashbord/login`, {
            email: user,
            password: password,
        })
            .then(response => {
                console.log(response);
                cookies.set("token", response.data.data.token, { expires: new Date(Date.now() + 604800000) });
                localStorage.setItem('role', response.data.data.role)
                window.location.href = "/Cate2";
                this.setState({ spinBtn: false })
            })
            .catch(error => {
                if (error.response) {
                    this.setState({ spinBtn: false })
                    toaster.danger("الرجاء التحقق من اسم المستخدم او كلمة المرور");
                }
            });
    }
    submit() {
        let name = document.getElementById('userPhone').value;
        let password = document.getElementById('userPassword').value;
        axios.post(host + 'users/auth/login', {
            phone: name,
            password: password
        })
            .then(function (response) {
               window.location.href = '/'
                cookies.set("token", response.data.data.token, {
                    path: "/",
                    expires: new Date(Date.now() + 604800000)
                });
            })
            .catch(function (error) {
                toaster.danger("الرجاء التحقق من رقم الهاتف  او كلمة المرور");
            });
    }

    render() {
        return (
            <Context.Consumer>{ctx => {
                // if (ctx.value.login==='false') {
                return (
                    <div style={{ height: '100vh' }}>
                        <section style={{ overflow: "auto", height: "100vh" }} class="background-radial-gradient">

                            <div class="container px-4 py-5 px-md-5 text-center text-lg-start my-5">
                                <div class="row gx-lg-5 align-items-center mb-5">



                                    <div class="col-lg-6 mb-5 mb-lg-0 position-relative">
                                        <div id="radius-shape-1" class="position-absolute rounded-circle shadow-5-strong"></div>
                                        <div id="radius-shape-2" class="position-absolute shadow-5-strong"></div>

                                        <div class="card bg-glass">
                                            <div class="card-body px-4 py-5 px-md-5">
                                                <Tabs
                                                    defaultActiveKey="profile"
                                                    id="uncontrolled-tab-example"
                                                    className="mb-3"
                                                    defaultActiveKey="home"

                                                    style={{ display: 'flex', justifyContent: "end" }}
                                                >
                                                    <Tab eventKey="profile" title="المسؤولين">
                                                        <div>


                                                            <div class="form-outline mb-4">
                                                                <label class="form-label" style={{ display: "flex", justifyContent: "end" }} for="form3Example3">اسم المستخدم</label>
                                                                <input type="text" id="adminUserName" class="form-control" />

                                                            </div>

                                                            <div class="form-outline mb-4">
                                                                <label class="form-label" style={{ display: "flex", justifyContent: "end" }} for="form3Example4">كلمة المرور</label>
                                                                <input type="password" id="adminUserPassword" class="form-control" />

                                                            </div>


                                                            <button onClick={(e) => {
                                                                this.signINDash(e)
                                                            }} class="btn btn-primary btn-block mb-4">
                                                                تسجيل دخول كـ مسؤول
                                                            </button>


                                                        </div>
                                                    </Tab>
                                                    <Tab eventKey="home" title="الموظفين">
                                                        <div>


                                                            <div class="form-outline mb-4">
                                                                <label class="form-label" style={{ display: "flex", justifyContent: "end" }} for="form3Example3">رقم الهاتف</label>
                                                                <input type="number" id="userPhone" class="form-control" />

                                                            </div>

                                                            <div class="form-outline mb-4">
                                                                <label class="form-label" style={{ display: "flex", justifyContent: "end" }} for="form3Example4">كلمة المرور</label>
                                                                <input type="password" id="userPassword" class="form-control" />

                                                            </div>


                                                            <button onClick={(e) => {
                                                                this.submit(e)
                                                            }} class="btn btn-primary btn-block mb-4">
                                                                تسجيل دخول كـ موظف
                                                            </button>


                                                        </div>
                                                    </Tab>


                                                </Tabs>

                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 mb-5 mb-lg-0" style={{ zIndex: 10 }}>
                                        <h1 class="my-5 display-5 fw-bold ls-tight" style={{ color: " hsl(218, 81%, 95%)", textAlign: "end" }}>
                                            نظام انفراد<br />
                                            <span style={{ color: "hsl(218, 81%, 75%)" }}>افضل اختيار لتطوير وادارة عملك</span>
                                        </h1>
                                        {/* <p class="mb-4 opacity-70" style={{ color: "hsl(218, 81%, 85%)" }}>
                                            Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                                            Temporibus, expedita iusto veniam atque, magni tempora mollitia
                                            dolorum consequatur nulla, neque debitis eos reprehenderit quasi
                                            ab ipsum nisi dolorem modi. Quos?
                                        </p> */}
                                    </div>
                                </div>
                            </div>
                        </section>

                    </div>
                )
            }
                // else if (ctx.value.login==='true') {
                //   window.location.href='/home'
                // }
                // else {
                //   return (
                //     <div style={{ width: '100%', height: 'calc(100vh - 80px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                //       <Lottie
                //         options={{
                //           animationData: animation
                //         }}
                //       />
                //     </div>
                //   )
                // }
            }
            </Context.Consumer >
        );
    }
}
export default Login;