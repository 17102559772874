import React from "react";
import Context from "../../assets/js/Context.js";
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FcApproval } from "react-icons/fc";
import Blocked from "../../assets/img/expired.json";
import Lottie from "lottie-react";

class Orders extends React.Component {
    constructor() {
        super();
        this.displayDataAdt = [];
        this.displayDataAdt2 = [];
        this.state = {
        };

    }
    render() {

        return (
            <div>
                <Context.Consumer>
                    {ctx => {
                        return (
                            <div style={{ height: '100vh', width: '100%', display: 'flex',flexDirection:'column', alignItems: 'center', justifyContent: 'center' }}>
                                  
                                  <Lottie animationData={Blocked} loop={true} />
                            
                                <div style={{ display: 'flex' ,textAlign:"center",margin:20}}>
                                    <h4>انتهت صلاحية الاشتراك يرجى التواصل معنا</h4>


                                </div>
                         
                            </div>
                        )


                    }}
                    
                </Context.Consumer>
            </div>
        );
    }
}

export default Orders;